import * as React from 'react';
import {useCallback, useState} from "react";
import {InputField, MessageInfo} from '../types/';
import * as API from '../utils/api'
import {InputCard} from "../molecules/InputCard";
import {getFieldByPropName} from "../utils/utils";
import {SavedFeedBack} from "../molecules/SavedFeedback";


interface PasswordResetRequestCardProps {
    onComplete: () => void
}

const PasswordResetRequestCard: React.FC<PasswordResetRequestCardProps> = ({ onComplete }) => {

    const [message, setMessage] = useState<MessageInfo>()

    const getInputField = (): InputField<string>[] => ([
        {type: 'text', propName: 'email', label: 'メールアドレス', value: '', required: true},
    ])

    const setInputFieldToLogin = (passwordReset: { email: string }, fields: InputField<string>[]) => {
        passwordReset.email = getFieldByPropName(fields, 'email')?.value || '';
    }

    const onAction = useCallback(async (fields: InputField<string>[]) => {
        const passwordReset = {email: ''}
        setInputFieldToLogin(passwordReset, fields);
        await API.post<{ success: boolean }>(`/user/init_reset_password`, passwordReset).then((data) => {
            if (data?.success === true) {
                setMessage({
                    message: 'パスワードリセットのメールをお送りしました。',
                    timestamp: new Date().getTime(),
                })
                setTimeout(() => {
                    onComplete()
                }, 1000)
            }
        })
    }, [setMessage, onComplete])

    return (
        <React.Fragment>
            <InputCard
                id={'reset-password'}
                title={'パスワードリセットを行うメールアドレスを入力してください。'}
                fields={getInputField()}
                onAction={onAction}
                actionLabel={'リセットメールを送る'}
                heightFixed={false}
                noDialog={true}
            />
            <SavedFeedBack feedbackText={message?.message || ''} savedTimestamp={message?.timestamp || 0}/>
        </React.Fragment>
    );
}

export default PasswordResetRequestCard;