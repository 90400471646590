import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {useCallback, useEffect, useState} from "react";
import {useRouter} from "next/router";
import LoginCard from "../organisms/LoginCard";
import * as API from "../utils/api";
import Dialog from "@mui/material/Dialog";
import PasswordResetRequestCard from "../organisms/PasswordResetRequestCard";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {NewsItem} from "../types";
import FiberNewIcon from '@mui/icons-material/FiberNew';
export default function Home() {
    const router = useRouter();
    const [loaded, setLoaded] = useState(false)
    const [openResetPassword, setOpenResetPassword] = useState(false)
    const [news, setNews] = useState<NewsItem[]>([])
    useEffect(()=>{
        document.title = 'ログイン - New Relic Dojo Web'
        API.get<{ news: NewsItem[] }>('/public/news').then((data) => {
            setNews(data.news)
        })
    },[setNews])
    useEffect(()=>{
        API.post<{ success: boolean }>('/hello', {}, {}, false).then((data) => {
            if (!!data && data.success) {
                return router.push('/dashboard')
            } else {
                setLoaded(true)
            }
        }).catch(e => { console.error(e) })
    }, [setLoaded])

    const handleLogin = useCallback(() => {
        router.push('/dashboard').catch(e => { console.error(e) })
    }, [router])

  return (
      <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
          {loaded && (
          <Grid container spacing={3}>
              <Grid size={12} onClick={()=>handleLogin()}>
                  気軽に学べるNew Relicの学習サイト (毎日 9:00 - 22:00 Open! 現在 土日稼働も実施中)
              </Grid>
              <Grid size={12}>
                  <LoginCard onLogin={()=>handleLogin()} />
              </Grid>
              <Grid size={12} sx={{ display: 'flex', flexDirection: 'row-reverse'}}>
                  <Button onClick={()=>setOpenResetPassword(true)}>パスワードをリセットする</Button>
              </Grid>
              <Grid size={12}>
                  <Paper elevation={0} sx={{ padding: "16px"}}>
                      <Grid container spacing={3}>
                          {news.map(newsItem => (<>
                              <Grid size={3}>{newsItem.date}</Grid>
                              {newsItem.course !== undefined?
                                  (<Grid size={8}>コース：「{newsItem.course}」を開講しました。</Grid>)
                                  : newsItem.feature !== undefined ?
                                      (<Grid size={8}>{newsItem.feature} を追加しました。</Grid>)
                                      : newsItem.other !== undefined ?
                                          (<Grid size={8}>{newsItem.other}</Grid>)
                                          : (<></>)
                              }
                              <Grid size={1}>{(new Date ().getTime() - new Date(newsItem.date).getTime()) < 7 * 24 * 60 * 60 * 1000 && (<FiberNewIcon color="primary" />) }</Grid>
                          </>))}
                      </Grid>
                  </Paper>
              </Grid>
              <Grid size={2} />
          </Grid>
          )}
          <Dialog open={openResetPassword}
                  onClose={()=>setOpenResetPassword(false)}>
              <PasswordResetRequestCard onComplete={()=>setOpenResetPassword(false)} />
          </Dialog>
      </Container>
  )
}
